import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Img from "gatsby-image";
import Grid from "@material-ui/core/Grid";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import Layout from "../components/layout";
import Seo from "../components/seo";

import '../fonts/SourceSansPro/SourceSansPro-Regular.ttf';

import "../css/app.css";

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(contentful_id: { eq: "3OfyAYBPFolQwKxJEWVmSx" }) {
        id
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      contentfulHome(contentful_id: { eq: "4INJEtKpNVWj8YbwPMUUqO" }) {
        id
        body {
          json
        }
        intro {
          json
        }
        bodyEnd {
          json
        }
        title
        link1image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
        link2image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
        link3image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
        link4image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
      }
    }
  `);

  const { title, body, intro, bodyEnd, link1image, link2image, link3image, link4image } = data.contentfulHome;
  const { fluid, description } = data.contentfulAsset;

  return (
    <Layout>
      <Seo title="Praktijk voor zelfliefde" description="Praktijk voor zelfliefde" />
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="space-between"
        style={{ marginBottom: "4rem" }}
      >
        <Grid item xs={12}>
          <div className="textStyleTitle">
            {title}
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={0} style={{ width: "100%", height: "50vh" }}>
            <Img
              fluid={fluid}
              fadeIn
              alt={description}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ objectFit: 'contain' }}
              backgroundColor={ "#fafafa" }
            ></Img>
          </Paper>
        </Grid>
       {/*  <Grid item xs={12} md={12}>
          <Typography color="textPrimary" component="div" align="center">
            {documentToReactComponents(intro.json)}
          </Typography>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <Typography color="textPrimary" component="div" align="center">
            {documentToReactComponents(body.json)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography color="textPrimary" component="div" align="center">
            {documentToReactComponents(bodyEnd.json)}
          </Typography>
        </Grid>
            <Grid item xs={12} md={6}>
              <Link href="overmijenzelfliefde">
                <div className="container">
                  <Img
                    fluid={link1image.fluid}
                    fadeIn
                    alt={link1image.description}
                    style={{ width: "100%", height: "100%" }}
                    imgStyle={{ objectFit: 'contain' }}
                    tr
                  ></Img>
                   <div className="textStyle">
                    Over mij
                   </div>
                </div>
              </Link>
            </Grid> 
            <Grid item xs={12} md={6}>
              <Link href="overmijenzelfliefde">
                <div className="container">
                  <Img
                    fluid={link2image.fluid}
                    fadeIn
                    alt={link2image.description}
                    style={{ width: "100%", height: "100%" }}
                    imgStyle={{ objectFit: 'contain' }}
                  ></Img>
                   <div className="textStyle">
                    Zelfliefde
                   </div>
                </div>
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
            <Link href="massageencoaching">
            <div className="container">
              <Img
                fluid={link3image.fluid}
                fadeIn
                alt={link3image.description}
                style={{ width: "100%", height: "100%" }}
                imgStyle={{ objectFit: 'contain' }}
              ></Img>
              <div className="textStyle">
              Coaching
                   </div>
              </div>
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
            <Link href="contact">
            <div className="container">
              <Img
                fluid={link4image.fluid}
                fadeIn
                alt={link4image.description}
                style={{ width: "100%", height: "100%" }}
                imgStyle={{ objectFit: 'contain' }}
              ></Img>
              <div className="textStyle">
              Contact
                   </div>
              </div>
              </Link>
            </Grid>
      </Grid>
    </Layout>
  );
}
